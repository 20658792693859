import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useLinkClickHandler, useNavigate } from "react-router-dom";
import ChatMessage from "../components/ChatMessage";
import Api from "../services/Api";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeQuestsdata } from "../actions/questActions";
import { setQuestsdata } from "../actions/questActions";
const plus = require('../assets/plus-white.png')
const minus = require('../assets/minus-white.png');

const CharacterDetails = (props) => {

    const { Character, history = null } = props;
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const isLogged = useSelector((state) => state.user.isLogged)
    const [_helmet, setHelmet] = useState({ title: '', des: '' })
    const [message, setMessage] = useState('')
    const [character_id, setCharacter_id] = useState(0)
    const [modelNotAvilable, setModelNotAvilable] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);
    const [sending, setSending] = useState(false);
    const [chatID, setChatID] = useState(0)
    const [_alert, setAlert] = useState({ variant: '', message: '' })

    const location = useLocation();
    const dispatch = useDispatch()

    const selected = useSelector((state) => state.quests.selected)

    const send = (e) => {
        setAlert({})
        e.preventDefault();

        if (message.trim() == "") {
            return
        }
        let _height = document.querySelector('.start_chat')?.offsetHeight || 0;
        let obj = {
            dataTime: new Date().getTime(),
            message: message,
            by: 'U'
        };

        let Chatobj = {
            dataTime: new Date().getTime(),
            message:
                "Typing...",
            by: 'C'
        };

        setChatMessages(prev => [...prev, obj, Chatobj]);

        setSending(true)
        Api.post('chat/sendMessage', {
            "chat_id": chatID,
            "character_id": character_id,
            "message": message
        })
            .then((resData) => {
                if (resData.success) {
                    if (resData.data.list.allow_verify) {
                        setModelNotAvilable(false);
                    } else {
                        setModelNotAvilable(true);
                    }
                    let res = resData.data.list.message;
                    let msg = res.split(' ');
                    let _text = '';
                    let end = msg.length;
                    let reached = 0;
                    setChatID(resData.data.list.chat_id)
                    let interval = setInterval(() => {
                        if (reached == end) {
                            clearInterval(interval)
                        } else {
                            _text = _text + msg[reached] + " ";
                            reached++
                            let obj = {
                                dataTime: new Date().getTime(),
                                message: _text,
                                by: "C"
                            }
                            setChatMessages(prev => {
                                let _prev = prev
                                _prev[prev.length - 1] = obj
                                return [..._prev]
                            })
                        }
                        navigate(`/chat/${Character.id + '-' + Character.seo + '/' + resData.data.list.chat_id}`)
                    }, 10)
                } else {
                    setAlert({ message: 'Some error occured!', variant: 'danger' })
                }
            })
            .catch((err) => {
                setAlert({ message: 'Some error occured!', variant: 'danger' })
            })
            .finally(() => {
                setSending(false)
            })
        setMessage('')
    }

    const chatScreen = (e) => {
        e.preventDefault()
        if (isLogged) {
            navigate(`/chat/${Character.id}-${(Character?.seo || 'models')}`)
        } else {
            navigate('/login', { state: { from: location.pathname } })
        }
    }

    useEffect(() => {
        setCharacter_id(Character.id)
    }, [Character])

    useEffect(() => {
        setLoading(true)
        setHelmet({ title: Character.seo, des: Character.description })
        Api.post(`chat/detail`, {
            chat_id: 1,
            character_id: Character.id
        })
            .then((res) => {
                // console.log(res)
                if (res.success) {
                    setData(res.data);
                } else {
                    setData(null);
                }
            })
            .catch(() => {
                setData(null);
            })
            .finally(() => {
                setLoading(false);
            })

    }, [])

    const updateLike = (e) => {

        if (selected.find((item) => item.id == e.id)) {
            selected.filter((item) => item.id != e.id)
            dispatch(removeQuestsdata(e))
        } else {
            dispatch(setQuestsdata(e))
        }

        // Api.post('characters/like',
        //   {
        //     type: e.liked ? 0 : 1,
        //     character_id: JSON.stringify(e.id)
        //   })
        //   .then((res) => {
        //     if (res.success) {
        //       let _data = []
        //       data.map((ele) => {
        //         if (ele.id == e.id) {
        //           ele['liked'] = e.liked ? false : true
        //           _data.push(ele)
        //         } else {
        //           _data.push(ele)
        //         }
        //       })
        //       setData(_data)
        //     } else {

        //     }
        //   })
        //   .catch(() => {

        //   })
    }

    return (
        <>
            <Helmet>
                <title>{Character.seo}</title>
                <meta name="description" content={Character.description} />
                <meta name="description" content={Character.description} />
                <meta property="og:url" content="https://www.nestheads.ai/models" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={Character.seo} />
                <meta property="og:description" content={Character.description} />
                <meta property="og:image" content={Character.image} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="nestheads.ai" />
                <meta property="twitter:url" content="https://www.nestheads.ai/models" />
                <meta name="twitter:title" content={Character.seo} />
                <meta name="twitter:description" content={Character.description} />
                <meta name="twitter:image" content={Character.image} />
            </Helmet>
            {loading ?
                <div style={{ backgroundColor: 'rgb(250, 250, 250)', borderRadius: '15px', height: '300px', width: '100%', marginTop: '130px' }} ></div>
                : <div className="character_details" style={{ position: 'relative' }} >
                    <div className="img p-2 p-md-0 " >
                        {Character.image ? <img src={Character.image} alt={Character.name} /> : <div className="w-75 h-75 w-md-100" style={{ margin: '25px', backgroundColor: "#ccc", borderRadius: '50%' }} > </div>}
                    </div>
                    {Character.name ?
                        <div className="character_details_section my-4">
                            <h1 className="title">{Character.name}</h1>
                            <div className="character_details_des">{Character.description}</div>
                            {/* <div className="character_details_star">
                            <img src={require('../assets/Character/star.png')} width={20} alt={"star"} />
                            <div className="mx-2">{Character?.heading || ''}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', fontFamily: "Dimbo", marginBottom: "20px" }} >
                            <a href={`/chat/${Character.id}-${(Character?.seo || 'models')}`} onClick={chatScreen} style={{ textDecoration: 'none', padding: '10px 30px', backgroundColor: 'black', borderRadius: '25px', color: 'white', minWidth: '156px', minHeight: '47px', textAlign: 'center', cursor: 'pointer' }}>Chat Now</a>
                        </div> */}
                            <div style={{ position: 'absolute', top: '20px', right: '20px' }} >
                                <div className={"star-icon " + (selected.find((item) => item.id == Character.id) ? "active" : "")} onClick={() => updateLike(Character)}  >
                                    <img src={selected?.find((item) => item.id == Character.id) ? minus : plus} alt={Character.liked ? 'plus' : "minus"} />
                                </div>
                            </div>
                        </div> : <div className="title">Model not available!</div>}
                </div>}
            {/* {isLogged && Character.name && <div>
                {_alert?.message && <div style={{ marginTop: '20px' }} ><Alert dismissible variant={_alert.variant}>{_alert.message}</Alert></div>}
                <form onSubmit={(e) => { sending ? e.preventDefault() : send(e) }} >
                    <div className='chat_field_details'>
                        <input placeholder='Message' value={message} onChange={(e) => setMessage(e.target.value)} />
                        <div className='chat_btn' onClick={(e) => { sending ? e.preventDefault() : send(e) }}  > {sending ? <Spinner size="sm" variant="light" /> : <img src={require('../assets/arrow_up.png')} />}</div>
                    </div>
                </form>
            </div>} */}
            {/* {Character.name && */}
            {false &&
                <div className="character_details_bottom">
                    <Accordion flush alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Real World Examples</Accordion.Header>
                            <Accordion.Body>
                                <div style={{ padding: "0 20px" }} >
                                    {Character?.example &&
                                        Character.example.map((e, i) => {
                                            return <li key={i} style={{ color: 'rgba(30, 30, 30, 0.5)' }} >{e}</li>
                                        })
                                    }
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Pros and Cons</Accordion.Header>
                            <Accordion.Body>
                                <div style={{ width: '100%', display: 'grid', gridTemplateColumns: "1fr 1fr", color: 'rgba(30, 30, 30, 0.5)' }} >
                                    {Character?.pros && <div  >
                                        <div style={{ backgroundColor: 'rgba(146, 207, 213, 1)', padding: '12px 15px', fontFamily: 'Dimbo', fontSize: 20 }} >Pros of Consicence</div>
                                        <div style={{ borderRight: "1px solid rgba(210, 210, 210, 1)" }}>
                                            {Character?.pros.map((e, i) => {
                                                return <div key={i} style={{ padding: "5px 15px" }}>{e}</div>
                                            })}
                                        </div>

                                    </div>}
                                    {Character?.cons && <div >
                                        <div style={{ backgroundColor: 'rgba(146, 207, 213, 1)', padding: ' 12px 15px', fontFamily: 'Dimbo', fontSize: 20 }} >Cons of Consicence</div>
                                        <div>
                                            {Character?.cons.map((e, i) => {
                                                return <div key={i} style={{ padding: "5px 15px" }}>{e}</div>
                                            })}
                                        </div>
                                    </div>}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        {isLogged && Character?.name && <Accordion.Item eventKey="2">
                            <Accordion.Header>Chat History</Accordion.Header>
                            <Accordion.Body>
                                {Character.chat_messages ?
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {Character.chat_messages.map((e, i) => {
                                            return (
                                                <div key={i} >
                                                    <ChatMessage msgdata={e} character={Character} />
                                                </div>
                                            )
                                        })}
                                    </div>
                                    :
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                        <div style={{ fontSize: 20, fontFamily: 'Dimbo' }} >No chat found!</div>
                                        <div style={{ padding: '0px 10px', display: 'flex', justifyContent: 'flex-end', fontFamily: "Dimbo" }} >
                                            <a href={isLogged ? `/chat/${Character.id}-${Character?.seo || "model"}` : '/login'} onClick={(e) => { e.preventDefault(); isLogged ? navigate(`/chat/${Character.id}-${Character?.seo || "model"}`) : navigate('/login') }} style={{ padding: '10px 30px', backgroundColor: 'black', borderRadius: '25px', color: 'white', minWidth: '156px', minHeight: '47px', textAlign: 'center', cursor: 'pointer', textDecoration: 'none' }}> {"Start Chat Now"}</a>
                                        </div>
                                    </div>
                                }
                            </Accordion.Body>
                        </Accordion.Item>}
                        <Accordion.Item eventKey={Character?.name && isLogged ? "3" : 2}>
                            <Accordion.Header>My Nestheads</Accordion.Header>
                            <Accordion.Body>
                                {Character.chat_messages ?
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {Character.chat_messages.map((e, i) => {

                                            if (i == Character.chat_messages.length - 2) {
                                                return (
                                                    // <ChatMessage msgdata={e} character={Character} />
                                                    <div key={i} className="my_nestheads" >
                                                        <div style={{ height: '100px', padding: '10px', backgroundColor: "#ccc", borderRadius: '10px' }} >
                                                            {e.message}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                    : <></>
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>}
        </>
    )
}

export default CharacterDetails