import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Naviagation from './screens/Navigation';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AOS from "aos"

function App() {

  useEffect(()=> {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
    });
  },[])

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Naviagation />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;